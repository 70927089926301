<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t( translationPath + ( currentUser.strict_accounting_mode ? 'title_income_accounts' : 'title_income_categories')) }}</h1>
      </b-row>
      <account-plan-submenu active="incomes"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-incomes-header></account-plan-incomes-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs" @activate-tab="onTabActivate">
            <IncomeMenu>
              <template slot="account-plan-incomes-tink">
                <b-row no-gutters>
                  <b-col cols="8">
                    <div class="text-regular" v-html="$t(translationPath + 'income.tink.description')"></div>
                  </b-col>
                </b-row>
                <b-table class="spirecta-simple-table" show-empty hover
                         responsive striped
                         stacked="md"
                         :items="tableRows"
                         :fields="fieldValues"
                         :tbodyTrClass="trClass"
                         :busy="busy"
                >
                  <template v-slot:table-busy>
                    <loader/>
                  </template>

                  <template v-slot:cell(account_id)="row">
                    <custom-select
                        v-model="row.item.account"
                        :options="accountGroups.income"
                        grouping-values="accounts"
                        :grouping-label="currentCOA.prefix_account_title_with_code ? 'title_with_code' : 'title'"
                        :label="currentCOA.prefix_account_title_with_code ? 'title_with_code' : 'title'"
                        :placeholder="$t('common.select_account').toString()"
                        :search-placeholder="$t('common.type_to_filter').toString()"
                        @select="hasUnsavedChanges = true"
                    ></custom-select>
                  </template>
                </b-table>

                <b-row class="mb-5 mt-4">
                  <b-col class="pt-4 pt-md-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="float-right ml-3 px-4 btn-save"
                      :disabled="saveDisabled || busy"
                      @click="onSave"
                    >{{ $t('common.save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </IncomeMenu>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>
  </b-container>
</template>

<script>
import AccountPlanTabMixin from './AccountPlanTabMixin'
import CustomSelect from '@/components/common/CustomSelect'
import axios from 'axios'
import { mapState } from 'vuex'
import IncomeMenu from './submenu/IncomeMenu'
import AccountPlanIncomesHeader from './headers/AccountPlanIncomesHeader'

export default {
  name: 'AccountPlanIncomesTink',
  mixins: [AccountPlanTabMixin],
  components: { CustomSelect, IncomeMenu, AccountPlanIncomesHeader },
  data () {
    return {
      tableRows: [],
      tincCategories: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    fieldValues () {
      return [
        { key: 'tink_category', label: this.$t(this.translationPath + 'table.th_tink_category') },
        { key: 'account_id', label: this.$t('common.' + (this.currentUser.strict_accounting_mode ? 'account' : 'category')) }
      ]
    }
  },
  methods: {
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }
      this.busy = true

      Promise.all([
        this.loadAccountGroups('income'),
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/categories?type=income`)
          .then((response) => {
            this.tinkCategories = response.data.data
          })
      ]).then(() => {
        this.tinkCategories.map(category => {
          const row = {
            tink_category: category.primaryName + (category.secondaryName ? ' - ' + category.secondaryName : ''),
            tink_category_id: category.id,
            account: category.accounts.length ? category.accounts[0] : null
          }
          this.tableRows.push(row)
        })
        this.busy = false
      })
    },
    onSave () {
      this.saveDisabled = true

      const putData = {
        data: [],
        type: 'income'
      }

      this.tableRows.map(row => {
        if (row.account) {
          putData.data.push({ account_id: row.account.id, tink_category_id: row.tink_category_id })
        }
      })

      axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/update-tink-categories`, putData)
        .then(() => {
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_tink_income_success_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_tink_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.hasUnsavedChanges = false
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_tink_income_fail_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_tink_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.saveDisabled = false
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
</style>
